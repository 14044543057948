import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { ChevronRight } from '@/components/atoms/assets/ChevronRight'
import { Button } from '@/components/atoms/Button'
import { Loading } from '@/components/atoms/Loading'
import { ShareButton } from '@/components/molecules/ShareButton'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { useTokenActivities } from '@/lib/hooks/useTokenActivities'
import { useTokenDetails } from '@/lib/hooks/useTokenDetails'
import { Routes } from '@/router/routes'
import { numberFormat } from '@/utils/currency'

import { Chart } from './Chart'

type StoxPreviewProps = {
  userInfo: UserInfo
  isAuthUser: boolean
}

export const StoxPreview: FC<StoxPreviewProps> = ({ userInfo, isAuthUser }) => {
  const { t } = useTranslation('common')
  const getAvatar = useGetAvatar()
  const {
    data: tokenDetails,
    isLoading: isLoadingTokenDetails,
    error: tokenDetailsError,
  } = useTokenDetails(userInfo?.creatorTokenId)
  const {
    data: tokenActivities,
    isLoading: isLoadingTokenActivities,
    error: tokenActivitiesError,
  } = useTokenActivities(userInfo?.creatorTokenId)

  const openExternalMarket = useCallback(() => {
    window.open(`${appConfig.marketAppOrigin}/@${userInfo?.username}`)
  }, [userInfo?.username])

  const now = new Date().getTime()
  const tokenDetailsCreatedAt = new Date(tokenDetails?.createdAt).getTime()
  const is24hSinceTokenCreation = useMemo(
    () => now - tokenDetailsCreatedAt > 24 * 60 * 60 * 1000,
    [tokenDetailsCreatedAt] // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (isLoadingTokenDetails || isLoadingTokenActivities) return <Loading />

  return (
    <div className="absolute top-0 left-0 w-full flex flex-col p-5 pl-7 md:p-6 md:pl-9">
      {!(tokenDetailsError || tokenActivitiesError) ? (
        <div className="flex justify-between items-end mb-8 md:mb-10">
          <div className="w-[40%] flex flex-col items-start">
            <span className="text-sm md:text-lg text-cool-gray-300 font-normal mb-1">
              <Trans>
                {isAuthUser
                  ? t('profile.creatorToken.preview.mySTOXPrice', {
                      creatorToken: appConfig.creatorToken,
                    })
                  : t('profile.creatorToken.preview.userSTOXPrice', {
                      username: userInfo?.username,
                      creatorToken: appConfig.creatorToken,
                    })}
              </Trans>
            </span>
            <span className="flex items-end text-2xl md:text-4xl font-bold">
              {tokenDetails?.price ? numberFormat(tokenDetails?.price) : '-'}
              <span className="text-sm md:text-lg text-cool-gray-300 ml-1">
                {appConfig.currency}
              </span>
            </span>
            <span
              className={cx('flex items-baseline text-sm md:text-lg', {
                'text-success': tokenDetails?.priceChange24h > 0,
                'text-danger': tokenDetails?.priceChange24h < 0,
              })}
            >
              {tokenDetails?.priceChange24h > 0 && '+'}
              {tokenDetails?.priceChange24h?.toFixed(2) ?? '-'}%
              <span className="text-xs md:text-base text-cool-gray-300 ml-1">
                ({t('profile.creatorToken.preview.last24h')})
              </span>
            </span>
          </div>
          <Chart
            className="w-[50%] h-24 md:h-32"
            activities={tokenActivities}
          />
        </div>
      ) : (
        <div className="w-full h-40 flex justify-center items-center">
          {t('profile.creatorToken.preview.errorMessage')}
        </div>
      )}
      <div className="flex flex-wrap gap-x-3 gap-y-4">
        <ShareButton
          className="h-8 flex justify-center !p-0 !m-0 cursor-pointer bg-primary rounded-full"
          color=""
          buttonLabel={
            <span className="not-italic text-xs font-bold normal-case">
              {t('profile.creatorToken.preview.shareThisSTOX', {
                creatorToken: appConfig.creatorToken,
              })}
            </span>
          }
          message={t('profile.creatorToken.preview.shareMessage', {
            username: userInfo?.username,
            creatorToken: appConfig.creatorToken,
          })}
          url={`${appConfig.marketAppOrigin}/@${userInfo?.username}`}
          imageUrl={getAvatar(userInfo?.id)}
          feature="stox-preview"
        />
        <Button fill="outline" onClick={openExternalMarket}>
          {t('profile.creatorToken.preview.tradeSTOX', {
            creatorToken: appConfig.creatorToken,
          })}
        </Button>
        {isAuthUser && (
          <Link
            className="flex items-center text-sm font-normal cursor-pointer"
            to={Routes.PRICE_BOOSTER}
          >
            <p className="hover:border-b">
              {t('profile.creatorToken.preview.boostYourSTOXPrice', {
                creatorToken: appConfig.creatorToken,
              })}
            </p>
            <ChevronRight className="w-4 h-4 ml-1" />
          </Link>
        )}
      </div>
      {!is24hSinceTokenCreation && (
        <div className="mt-5 text-sm md:text-lg text-cool-gray-300">
          <Trans>
            {t('profile.creatorToken.preview.listingNote', {
              creatorToken: appConfig.creatorToken,
            })}
          </Trans>
        </div>
      )}
    </div>
  )
}
