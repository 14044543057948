import React, { useContext } from 'react'

import { Context } from '@lib/store'
import { useOnboarding } from '@/lib/hooks/useOnboarding'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { pageStates } from '@/enums'

import { Onboarding } from '../Onboarding'

export const HomeOrLogin: React.FC = () => {
  const { state } = useContext(Context)
  const { data: userInfo } = useUserInfo()

  const isAuthenticated = !!userInfo && !!userInfo?.username

  const { pageState, finishOnboarding } = useOnboarding()

  if (pageState === pageStates.FirstLoaded && !isAuthenticated) {
    return <Onboarding finishOnboarding={finishOnboarding} />
  }

  if (pageState === pageStates.NonFirstLoaded && !isAuthenticated) {
    return <Navigate to={Routes.AUTH_SIGN_UP} />
  }

  if (state.signInRedirectPath) {
    const route = state.signInRedirectPath

    return <Navigate to={route} />
  }

  if (userInfo && !userInfo.deactivated) {
    return <Navigate to={Routes.PROFILE} />
  }

  return null
}
