import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { Login } from '@components/templates/login'

import { signIn } from '@services/auth'
import { errorBoundary } from '@/services/errorBoundary'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate, useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { AuthError } from '@/enums'

import SetPhoneNumber, { PhoneNumberFieldValues } from './SetPhoneNumber'

const PhoneNumber = () => {
  const navigate = useNavigate()
  const { data: authUser } = useUserInfo()
  const { control, handleSubmit } = useForm<PhoneNumberFieldValues>({
    defaultValues: {
      telNumber: { code: '+49', value: '' },
      geeCaptchaToken: null,
    },
  })

  const { mutate, isPending, error } = useMutation({
    mutationKey: ['signIn'],
    mutationFn: signIn,
    onSuccess: () => {
      navigate(Routes.AUTH_CONFIRM, {
        state: { referrer: 'phone' },
      })
    },
    onError: (error: Error) => {
      errorBoundary(error)
      if (error.toString().includes('UsernameExistsException')) {
        navigate(Routes.AUTH_CONFIRM)
      }
    },
  })
  const { t } = useTranslation('common')
  const onSubmit = async ({ telNumber, geeCaptchaToken }) => {
    if (!isPending) {
      mutate({
        username: telNumber.code + telNumber.value.replace(/^0/, ''),
        token: geeCaptchaToken,
        authType: 'PHONE_NUMBER',
      })
    }
  }

  const title = (
    <Trans i18nKey="registration.phoneNumber.title">
      {t('registration.phoneNumber.title')}
    </Trans>
  )

  if (
    authUser?.username &&
    !authUser?.deactivated &&
    location.pathname === '/phone'
  ) {
    return <Navigate to={Routes.PROFILE} />
  }

  return (
    <Login title={title} backButton>
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <SetPhoneNumber
          isErrored={{
            telNumber:
              error?.toString().includes('InvalidParameterException') ?? false,
            geeCaptchaToken:
              error?.toString().includes(AuthError.Captcha) ?? false,
            blockedPhoneNumber:
              error?.message.includes(AuthError.BlockedPhoneNumber) ?? false,
            disabledUser:
              error?.toString().includes(AuthError.DisabledUser) ?? false,
            tryLater: error?.message?.includes(AuthError.TryLater) ?? false,
            userNotFound:
              error?.message?.includes(AuthError.UserNotFound) ?? false,
          }}
          isLoading={isPending}
          control={control}
          submit={handleSubmit(onSubmit)}
        />
      </form>
    </Login>
  )
}

export default PhoneNumber
