import type { ReactNode } from 'react'
import { FC, useMemo } from 'react'
import { IonToolbar, isPlatform } from '@ionic/react'
import cx from 'classnames'

import { ActivityNavigation } from '@components/molecules/ActivityNavigation'
import { Explorer, ExplorerOutline } from '@/components/atoms/assets/Explore'
import { Home, HomeOutline } from '@/components/atoms/assets/Home'
import { Person, PersonOutline } from '@/components/atoms/assets/Person'
import { UGCButton } from '@/components/molecules/UGCButton'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'
import { isInStandaloneMode } from '@/utils/isInStandaloneMode'
export interface IMainTitleProps {
  children: ReactNode
  className?: string
}

interface INavigation {
  name: string
  label?: string
  path?: string
  icon?: JSX.Element
  activeIcon?: JSX.Element
}

interface NavBarProps {
  onActiveNavIconPress?: () => void | Promise<void>
}

export const NavBar: FC<NavBarProps> = ({ onActiveNavIconPress }) => {
  const path = window.location.pathname

  const { data: authUser } = useUserInfo()

  const navigation: INavigation[] = useMemo(
    () =>
      [
        {
          name: 'feed',
          path: Routes.FEED,
          icon: <Home />,
          activeIcon: <HomeOutline />,
        },
        {
          name: 'discover',
          path: Routes.DISCOVER,
          icon: <Explorer />,
          activeIcon: <ExplorerOutline />,
        },
        {
          name: 'UGCButton',
          path: Routes.POST,
        },
        {
          name: 'activity',
          path: Routes.ACTIVITY,
        },
        {
          name: 'profile',
          path: authUser ? '/@' + authUser?.username : Routes.PROFILE,
          icon: <Person />,
          activeIcon: <PersonOutline />,
        },
      ].filter(Boolean),
    [authUser]
  )

  if (appConfig.hideGetstream) return null

  return (
    <nav className="mx-auto max-w-[40rem] w-full relative z-50">
      <IonToolbar slot="bottom">
        <div
          className={cx('flex justify-between bg-cool-gray-900', {
            'pb-4 -m-[3px]': isInStandaloneMode() && isPlatform('ios'),
          })}
        >
          {navigation.map((item: INavigation) => {
            return (
              <Link
                className="flex-1 flex items-center justify-center text-center p-1 cursor-pointer"
                to={item.path}
                key={item.name}
                onClick={() => {
                  path === item.path ? onActiveNavIconPress() : undefined
                  const name = item.name || ''
                  const eventName = `${name[0].toUpperCase()}${name.slice(1)}`
                  TrackingEvent[eventName] &&
                    Tracking.triggerEvent(TrackingEvent[eventName])
                }}
              >
                {item.name === 'activity' ? (
                  <ActivityNavigation isActive={path === item.path} />
                ) : item.name === 'UGCButton' ? (
                  <UGCButton />
                ) : (
                  <div className="text-3xl md:text-2xl block mx-auto">
                    {path === item.path ? item.activeIcon : item.icon}
                  </div>
                )}
              </Link>
            )
          })}
        </div>
      </IonToolbar>
    </nav>
  )
}
