import { useState } from 'react'
import { FC } from 'react'

import { BlockUserAlert } from '@/components/molecules/Alert/BlockUserAlert'

import { appConfig } from '@/services/config'
import { useFollowButton } from '@/lib/hooks/useFollow'
import { useUserInfo } from '@/lib/hooks/useUserInfo'

import { FollowStatus } from '@enums'

import { FollowButtonBase } from './FollowButtonBase'

export interface IFollowButtonProps {
  userId: string
  followStatus?: FollowStatus
  size?: 'small' | 'default'
  className?: string
  username?: string
  onFollow?: (status: FollowStatus) => void
  color?: string
  fill?: 'outline' | 'clear'
}

export const FollowButton: FC<IFollowButtonProps> = ({
  userId,
  followStatus,
  size,
  className = '',
  username,
  onFollow,
  color = '',
  fill = 'clear',
}) => {
  const { data: userInfo } = useUserInfo()
  const { status, toggleFollow, toggleBlock } = useFollowButton(
    userId,
    followStatus
  )
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  if (appConfig.hideGetstream) return null

  if (userInfo?.id === userId) {
    return null
  }

  return (
    <>
      <BlockUserAlert
        isOpen={isPopoverVisible}
        onDismiss={() => setIsPopoverVisible(false)}
        onConfirm={() => {
          toggleBlock()
          setIsPopoverVisible(false)
        }}
        status={status}
        username={username}
      />
      <FollowButtonBase
        size={size}
        status={status}
        onFollow={() => {
          toggleFollow()
          onFollow?.(status)
        }}
        onBlock={() => setIsPopoverVisible(true)}
        className={className}
        color={color}
        fill={fill}
      />
    </>
  )
}
