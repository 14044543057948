import { FC, useContext, useEffect } from 'react'

import { Context } from '@lib/store'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const RedirectToLogin: FC = () => {
  const navigate = useNavigate()
  const { state } = useContext(Context)

  useEffect(() => {
    if (state.signInRedirectPath && state.signInRedirectPath !== Routes.ROOT) {
      navigate(state.signInRedirectPath, { replace: true })
    } else {
      navigate(Routes.PROFILE)
    }
  }, [navigate, state.signInRedirectPath])

  return null
}
