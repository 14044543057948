import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const useRedirect = () => {
  const navigate = useNavigate()

  const redirectToLogin = () => navigate(Routes.PROFILE)

  return { redirectToLogin }
}
