import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonIcon, IonTitle } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'

import { BackButton } from '@/components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Entry } from '@/components/templates/entry'
import { SocialsActionButton } from './components/SocialsActionButton'
import { SocialsList } from './components/SocialsList'
import { SocialsListDescription } from './components/SocialsListDescription'

import { useCreatorTokenConfig } from '@/lib/hooks/useLaunchToken'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { QueryKeys } from '@/enums'

export const TokenSocialsConnect: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('common')
  const { isLoading } = useCreatorTokenConfig()
  const { data: authUser } = useUserInfo()

  const { data: isLaunchTokenSuccess } = useQuery({
    queryKey: [QueryKeys.LaunchTokenSuccess],
  })

  useEffect(() => {
    if (isLaunchTokenSuccess || authUser?.creatorTokenId != null) {
      navigate(Routes.PROFILE)
    }
  }, [authUser?.creatorTokenId]) // eslint-disable-line react-hooks/exhaustive-deps

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton
          className="text-white"
          defaultHref={`/@${authUser?.username}`}
        />
      </IonButtons>
      <IonTitle className="!px-0">
        <Title level={3}>{t('common.head.title.tokenSocialsConnect')}</Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonIcon slot="icon-only" />
      </IonButtons>
    </>
  )

  if (isLoading) {
    return <Loading />
  }

  const bgStyles = 'absolute -z-10 w-full h-1/2 bg-center bg-cover bg-no-repeat'

  return (
    <Entry
      headTitle={t('common.head.title.tokenSocialsConnect')}
      header={header}
      className="h-full"
      contentClassName={`bg-[url('/trading_app/background.png')] md:bg-[url('/trading_app/desktop-background.png')] ${bgStyles}`}
    >
      <SocialsList />
      <SocialsListDescription />
      <SocialsActionButton />
    </Entry>
  )
}
