import { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useLocation, useNavigate } from '@/lib/routing'

import { ProfileHashTabs } from '@/enums'

import { sections } from '../components/UserProfileSections/sections'

export const useProfileSections = ({
  subscriptionFeedError,
  isSubscriptionFeedLoading,
  isSubscriptionFeedFetching,
  username,
}) => {
  const navigate = useNavigate()
  const { data: authUser } = useUserInfo()
  const { data: user } = useUserInfo({
    userId: `@${username}`,
    options: {
      enabled: Boolean(username),
    },
  })
  const { hash } = useLocation()
  const [normalizedSections, setSections] = useState([])

  const findSectionIndex = useCallback(
    (tab: ProfileHashTabs) => {
      const index = normalizedSections.findIndex(
        (section) => `#${section.name}` === tab
      )
      return index === -1 ? 0 : index
    },
    [normalizedSections]
  )

  const sectionIndex = useMemo(() => {
    return findSectionIndex(hash as ProfileHashTabs)
  }, [findSectionIndex, hash])

  const handleSetSections = useCallback(() => {
    if (isSubscriptionFeedFetching) {
      return
    }

    if (appConfig.hideGetstream) {
      const stoxSectionOnly = sections.filter((item) => item.name === 'STOX')
      setSections(stoxSectionOnly)

      return
    }

    const noSubscriptionSetupFound =
      (subscriptionFeedError as unknown as AxiosError)?.response?.status === 404

    const normalizedSections = noSubscriptionSetupFound
      ? sections.filter((item) => item.name !== 'subscription')
      : sections

    const normalizedSectionsEnd =
      authUser?.id === user?.id || user?.creatorTokenId != null
        ? normalizedSections
        : normalizedSections.filter((item) => item.name !== 'STOX')

    setSections(normalizedSectionsEnd)
  }, [
    isSubscriptionFeedFetching,
    subscriptionFeedError,
    authUser?.id,
    user?.id,
    user?.creatorTokenId,
  ])

  useEffect(() => {
    handleSetSections()
  }, [
    authUser?.id,
    isSubscriptionFeedLoading,
    subscriptionFeedError,
    user?.id,
    user?.creatorTokenId,
    handleSetSections,
  ])

  const navigateSectionIndex = (index: number) => {
    if (isSubscriptionFeedFetching) {
      return
    }
    const section = normalizedSections[index]
    const hash = section ? `#${section.name}` : ''
    navigate(`/@${username}${hash}`, { replace: true })
  }
  return {
    normalizedSections,
    sectionIndex,
    navigateSectionIndex,
    findSectionIndex,
  }
}
