import '../VERSION'
export interface IApplicationConfig {
  region?: string
  userPoolId?: string
  userPoolClientId?: string
  identityPoolId: string
  bucket: string
  kinesis: string
  api: string
  cachedApi: string
  cdn: string
  getstreamAppId: string
  getstreamApiKey: string
  stage: string
  gtmId: string
  amplitudeKey: string
  getstreamAnalyticsToken: string
  origin: string
  marketAppOrigin: string

  reCaptchaSiteKey?: string
  geeTestSignUpId?: string
  contestDetailsList: {
    limit: number
    preloadOffset: number
  }
  mentionsLimit: number
  aspectRatioLimit: {
    min: number
    max: number
  }
  isRerenderLogShown: boolean
  widgetExplorePageRank: number
  oneSignalAppId: string
  ssoVisible?: boolean
  googleIosClientId?: string
  googleWebClientId?: string
  facebookAppId?: string
  appleAppId?: string
  hideRecommendationsChip: boolean
  disableRecommendations: boolean
  versionTag: string
  republikUserId: string
  forceMobileWebPWA: boolean
  faceLivenessRegion: string
  faqLink?: string
  currency: string
  creatorToken: string
  instagramAppId?: string
  personalize?: string
  hideGetstream?: boolean
}

type Global = {
  applicationConfig: IApplicationConfig
  applicationVersion: { tag: string }
}

const global: Global =
  typeof window !== 'undefined' ? (window as unknown as Global) : ({} as Global)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalConfig = global.applicationConfig ?? ({} as Record<string, any>)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalVersion = global.applicationVersion ?? ({} as Record<string, any>)

export const appConfig: IApplicationConfig = {
  region: globalConfig?.region || process.env.REACT_APP_PUBLIC_AWS_REGION,
  userPoolId:
    globalConfig?.userPoolId || process.env.REACT_APP_PUBLIC_AWS_USER_POOL_ID,
  userPoolClientId:
    globalConfig?.userPoolClientId ||
    process.env.REACT_APP_PUBLIC_AWS_USER_POOL_CLIENT_ID,
  identityPoolId:
    globalConfig?.identityPoolId ||
    process.env.REACT_APP_PUBLIC_AWS_IDENTITY_POOL_ID,
  bucket: globalConfig?.bucket || process.env.REACT_APP_PUBLIC_AWS_BUCKET_NAME,
  kinesis: globalConfig?.kinesis || process.env.REACT_APP_PUBLIC_AWS_KINESIS,
  api: globalConfig?.api || process.env.REACT_APP_PUBLIC_API_URL,
  cachedApi:
    globalConfig?.cachedApi || process.env.REACT_APP_PUBLIC_CACHED_API_URL,
  cdn: globalConfig?.cdn || process.env.REACT_APP_PUBLIC_CDN,
  getstreamAppId:
    globalConfig?.getstreamAppId ||
    process.env.REACT_APP_PUBLIC_GETSTREAM_APP_ID,
  getstreamApiKey:
    globalConfig?.getstreamApiKey ||
    process.env.REACT_APP_PUBLIC_GETSTREAM_API_KEY,
  getstreamAnalyticsToken:
    globalConfig?.getstreamAnalyticsToken ||
    process.env.REACT_APP_PUBLIC_GETSTREAM_ANALYTICS_TOKEN,
  stage: globalConfig?.stage || process.env.REACT_APP_PUBLIC_STAGE,
  gtmId: globalConfig?.gtmId || process.env.REACT_APP_PUBLIC_GTM_ID,
  amplitudeKey:
    globalConfig?.amplitudeKey || process.env.REACT_APP_PUBLIC_AMPLITUDE_KEY,
  origin: globalConfig?.origin || process.env.REACT_APP_PUBLIC_ORIGIN,
  marketAppOrigin:
    globalConfig?.marketAppOrigin || process.env.REACT_MARKET_APP_PUBLIC_ORIGIN,
  reCaptchaSiteKey:
    globalConfig?.reCaptchaSiteKey || process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  geeTestSignUpId:
    globalConfig?.geeTestSignUpId || process.env.REACT_APP_GEE_TEST_SIGN_UP_ID,
  contestDetailsList: {
    limit: globalConfig?.contestDetailsList?.limit || 5,
    preloadOffset: globalConfig?.contestDetailsList?.preloadOffset || 2,
  },
  aspectRatioLimit: {
    min: 0.5,
    max: 1.8,
  },
  mentionsLimit:
    globalConfig?.mentionsLimit || process.env.MENTIONS_LIMIT || 10,
  isRerenderLogShown:
    globalConfig?.isRerenderLogShown ||
    process.env.REACT_APP_RERENDER_LOG === 'true',
  widgetExplorePageRank: 1,
  oneSignalAppId:
    globalConfig?.oneSignalAppId || process.env.REACT_APP_ONESIGNAL_APP_ID,
  ssoVisible:
    globalConfig?.ssoVisible ||
    process.env.REACT_APP_SSO_VISIBLE === 'true' ||
    false,
  googleIosClientId:
    globalConfig?.googleIosClientId ||
    process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID,
  googleWebClientId:
    globalConfig?.googleWebClientId ||
    process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID,
  facebookAppId:
    globalConfig?.facebookAppId || process.env.REACT_APP_FACEBOOK_APP_ID,
  appleAppId: globalConfig?.appleAppId || process.env.REACT_APP_APPLE_APP_ID,
  hideRecommendationsChip:
    globalConfig?.hideRecommendationsChip ||
    process.env.REACT_APP_HIDE_RECOMMENDATIONS_CHIP === 'true' ||
    false,
  disableRecommendations:
    globalConfig?.disableRecommendations ||
    process.env.REACT_APP_DISABLE_RECOMMENDATIONS === 'true' ||
    false,
  versionTag: globalVersion?.tag,
  republikUserId:
    globalConfig?.republikUserId || process.env.REACT_APP_REPUBLIK_USER_ID,
  forceMobileWebPWA:
    globalConfig?.forceMobileWebPWA ||
    process.env.REACT_APP_FORCE_MOBILE_WEB_PWA === 'true' ||
    false,
  faceLivenessRegion:
    globalConfig?.faceLivenessRegion ||
    process.env.REACT_APP_FACE_LIVENESS_REGION ||
    'eu-west-1',
  faqLink: globalConfig?.faqLink || process.env.REACT_APP_FAQ_LINK,
  // eslint-disable-next-line republik/no-rpk-string
  currency: 'RPK',
  creatorToken: 'STOX',
  instagramAppId:
    globalConfig.instagramAppId || process.env.REACT_APP_INSTAGRAM_APP_ID,
  personalize:
    globalConfig?.personalize || process.env.REACT_APP_PUBLIC_AWS_PERSONALIZE,
  hideGetstream:
    globalConfig?.hideGetstream || process.env.REACT_APP_GETSTREAM_HIDDEN,
} as IApplicationConfig
