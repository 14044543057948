import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

import { useGetSharedMedia } from '@/lib/hooks/sharing/useGetSharedMedia'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import LoadingPage from '../LoadingPage'

import { UgcComposerWebMedia } from '@/plugins/ugc-composer'

export const ShareTarget = () => {
  const { t } = useTranslation('common')
  const [presentToast, dismiss] = useIonToast()
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )

  const sharedUrl = searchParams.get('shared-url')
  const { data: userInfo } = useUserInfo()
  const isAuthenticated = !!userInfo && !!userInfo?.username
  const { data, isLoading, isError } = useGetSharedMedia(sharedUrl, {
    enabled: isAuthenticated && !!sharedUrl,
  })

  if (isLoading) {
    presentToast({
      position: 'middle',
      header: t('tikTokSharing.sharingInProgress'),
      duration: 3000,
    })

    return <LoadingPage />
  }
  dismiss()

  if (isError || data?.msg !== 'success') {
    presentToast({
      position: 'middle',
      header: t('tikTokSharing.sharingExceptionMessage'),
      duration: 3000,
    })
    return <Navigate to={Routes.PROFILE} />
  }

  const isVideo = !!data?.data?.wmplay?.includes('mime_type=video')
  const media: UgcComposerWebMedia = {
    url: isVideo ? data?.data?.wmplay : data?.data?.images?.[0],
    ratio: 9 / 16,
    mimeType: isVideo ? 'video/mp4' : 'image/jpeg',
    mediaType: isVideo ? 'video' : 'image',
    caption: data?.data?.title || '',
    isExternalMedia: true,
  }

  return <Navigate to={Routes.POST} state={{ media }} />
}
