import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { uniqueId } from 'lodash'

import { Chat } from '@/components/atoms/assets/Chat'
import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'
import { UnlockChatModal } from '@/components/organisms/UnlockChat'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { GetChatData } from '@/lib/hooks/chat/useChat'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'
import { useNavigate } from '@/lib/routing'

type ChatButtonProps = {
  page?: string
  chatData?: GetChatData
  isLoading?: boolean
  isError?: boolean
  userInfo: UserInfo
}

export const ChatButton: FC<ChatButtonProps> = ({
  page,
  chatData,
  isLoading,
  isError,
  userInfo,
}) => {
  const { t } = useTranslation('common')
  const currency = appConfig.currency
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const { data: authUser } = useUserInfo()
  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })
  const uuid = useMemo(() => uniqueId(), [])
  const [modalId, setModalId] = useState('')

  useEffect(() => {
    if (!isZeroBalance && chatData?.status === 'LOCKED') {
      setModalId(`send-chat-modal-${uuid}`)
    } else {
      setModalId('')
    }
  }, [chatData?.status, isZeroBalance, uuid])

  const navigate = useNavigate()
  const isActive = useMemo(() => !isLoading && !isError, [isLoading, isError])
  const routerLink = useMemo(() => {
    if (chatData?.status === 'LOCKED') return null

    return userInfo?.id ? `/messages/${userInfo?.id}` : `/messages?page=${page}`
  }, [userInfo, chatData, page])

  const onClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (isZeroBalance && chatData?.status === 'LOCKED') {
        depositCurrencyRef.current.openModal()
        return
      }

      if (chatData?.status === 'LOCKED') {
        setModalId(`send-chat-modal-${uuid}`)
        return
      } else {
        setModalId('')
      }

      navigate(routerLink)
    },
    [chatData?.status, navigate, isZeroBalance, routerLink, uuid]
  )

  if (appConfig.hideGetstream) return null

  return (
    <>
      <div
        id={modalId}
        className={cx({ 'cursor-pointer': isActive })}
        onClick={isActive ? onClick : undefined}
      >
        <div className="flex flex-row">
          <Chat isActive={isActive} />
          {isActive && chatData?.status !== 'FREE' && (
            <img
              className="relative w-3 h-[18px] -top-[2px] right-[7px]"
              src={
                chatData?.status === 'LOCKED'
                  ? '/locked-icon-green.svg'
                  : '/unlocked-icon-white.svg'
              }
              alt="lock-icon"
            />
          )}
        </div>
      </div>
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrencyToChat', {
          currency,
        })}
        ref={depositCurrencyRef}
      />
      <UnlockChatModal
        modalId={modalId}
        amount={chatData?.price}
        userInfo={userInfo}
      />
    </>
  )
}
